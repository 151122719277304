@import "~react-image-gallery/styles/css/image-gallery.css";

@media all and (min-width: 480px) {
  .LoginContainer {
    padding: 30px 0;
  }

  .LoginContainer form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.login-page-full-left-cont {
  height: 400px !important;
}
.login-signup-container {
  background-image: url("./../../img/loginBg.PNG");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  min-height: 600px;
}
.dont-have-acc {
  margin-top: 10px;
  text-align: center;
}
.dont-have-acc a {
  color: #007bff;
}
.error_message {
  color: red;
  text-align: center;
}

.login-page-full-container-con {
  display: flex;
  /* height: 100vh; */
}

.login-page-full-right-con {
  text-align: center;
}
.main-logo-login-page span {
  color: #1089ed;
  font-size: 44px;
  font-weight: 800;
  font-style: italic;
}
.login-page-full-container-con .login-page-full-left {
  width: 50%;
}

.login-page-full-container-con .login-page-full-right {
  width: 50%;
}
.login-to-your-acc-or {
  margin-bottom: 5px;
}
.login-to-your-acc {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 1px;
}
.create-to-your-acc a {
  font-weight: 500;
  color: #f9751b;
}
.login-page-full-left-cont svg {
  height: 50px !important;
  width: 50px !important;
}
.login-page-full-left-cont imh {
  max-height: 400px !important;
}
.login-page-full-right .login-container-sec {
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  padding: 20px 50px;
  padding-bottom: 50px;
}
.login-page-full-right .box-login-sec {
  text-align: left;
}
.login-page-full-right .box-login-sec label {
  color: rgb(55 65 81);
}
.login-page-full-right .box-login-sec input {
  height: 45px;
}
.login-page-full-right .box-login-sec input:focus {
  /* border: none; */
  box-shadow: none;
}
.login-page-full-right .login_btn_sec_acc,
.login-page-full-right .login_btn_sec_acc:hover {
  height: 47px;
  background: steelblue;
  border: none;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}
.login-container-sec .error_message {
  text-align: left;
  margin-bottom: 0px;
}

.login-page-full-container-con .login-page-full-right {
  padding: 50px;
}
.login-page-full-left {
  background: #006261;
  padding: 10px;
  /* background-color: green; */
  /* height: 100vh; */
}
.login-page-full-left .image-gallery-image {
  max-width: 100%;
}
.login-page-full-right input:-webkit-autofill {
  /* -webkit-text-fill-color: yellow !important; */
}

.login-page-full-right input:-webkit-autofill,
.login-page-full-right input:-webkit-autofill:hover,
.login-page-full-right input:-webkit-autofill:focus,
.login-page-full-right input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login-page-full-right-con-top img {
  max-width: 100px;
}
.keep-margin-login-check input {
  box-shadow: none !important;
  height: 20px;
  width: 20px;
}
.keep-margin-login-check label {
  margin-left: 7px !important;
  top: 1px;
  position: relative;
}
.keep-margin-login-check input,
.keep-margin-login-check label {
  cursor: pointer;
}
.keep-margin-login-check .form-check {
  margin-left: 5px;
}

.login-container-sec .dont-have-acc a {
  color: #f9751b;
}
.login-container-sec .dont-have-acc a:hover {
  color: #007bff;
}
.login-container-sec .forgot-pass-sec-login {
  font-size: 16px;
  /* text-decoration: underline; */
  /* color: #f9751b; */
  cursor: pointer;
}
.login-page-full-container-con1 .login-page-full-right-container {
  width: 50%;
  margin: auto;
}

.login-page-full-container-con1 .login-page-full-right {
  padding-top: 20px;
}
@media (max-width: 700px) {
  .login-page-full-left {
    display: none;
  }
  .login-page-full-right {
    width: 100% !important;
    padding: 20px !important;
  }
  .login-page-full-right .login-container-sec {
    padding: 20px 20px;
  }
}

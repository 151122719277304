@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&family=Rubik:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css');
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');

:root {
    
    --primary: #06A3DA;
    --secondary: #34AD54;
    --light:  #F6F9Fd !important;
    --dark: #091E3E;
}
.new-home-sec{
    background-color: #FFFFFF;


/********** Template CSS **********/



/*** Spinner ***/
.spinner {
    width: 40px;
    height: 40px;
    background: #06A3DA;
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 800 !important;
}

h3,
h4,
.fw-semi-bold {
    font-weight: 700 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 600 !important;
}


/*** Button ***/
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: .5s;
}

.btn-primary,
.btn-secondary {
    color: #FFFFFF;
    box-shadow: inset 0 0 0 50px transparent;
}

.btn-primary:hover {
    box-shadow: inset 0 0 0 0 #06A3DA;
}

.btn-secondary:hover {
    box-shadow: inset 0 0 0 0#34AD54;
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 30px;
    height: 30px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}




    


    /*** Carousel ***/
    .carousel-caption {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(9, 30, 62, .7);
        z-index: 1;
    }
    
    @media (max-width: 576px) {
        .carousel-caption h5 {
            font-size: 14px;
            font-weight: 500 !important;
        }
    
        .carousel-caption h1 {
            font-size: 30px;
            font-weight: 600 !important;
        }
    }
    
    .carousel-control-prev,
    .carousel-control-next {
        width: 10%;
    }
    
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 3rem;
        height: 3rem;
    }

    

/*** Section Title ***/
.section-title::before {
    position: absolute;
    content: "";
    width: 150px;
    height: 5px;
    left: 0;
    bottom: 0;
    background:  #06A3DA;
    border-radius: 2px;
}

.section-title.text-center::before {
    left: 50%;
    margin-left: -75px;
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 5px;
    bottom: 0px;
    background: #FFFFFF;
    -webkit-animation: section-title-run 5s infinite linear;
    animation: section-title-run 5s infinite linear;
}

.section-title.section-title-sm::after {
    width: 4px;
    height: 3px;
}

.section-title.text-center::after {
    -webkit-animation: section-title-run-center 5s infinite linear;
    animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
    -webkit-animation: section-title-run-sm 5s infinite linear;
    animation: section-title-run-sm 5s infinite linear;
}










/*** Service ***/
.service-item {
    position: relative;
    height: 300px;
    padding: 0 30px;
    transition: .5s;
}

.service-item .service-icon {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #06A3DA;
    border-radius: 2px;
    transform: rotate(-45deg);
}

.service-item .service-icon i {
    transform: rotate(45deg);
}

.service-item a.btn {
    position: absolute;
    width: 60px;
    bottom: -48px;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
}

.service-item:hover a.btn {
    bottom: -24px;
    opacity: 1;
}

















/*** Team ***/
.team-item {
    transition: .5s;
}

.team-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
}

.team-social a.btn {
    position: relative;
    margin: 0 3px;
    margin-top: 100px;
    opacity: 0;
}

.team-item:hover {
    box-shadow: 0 0 30px #DDDDDD;
}

.team-item:hover .team-social {
    background: rgba(9, 30, 62, .7);
}

.team-item:hover .team-social a.btn:first-child {
    opacity: 1;
    margin-top: 0;
    transition: .3s 0s;
}

.team-item:hover .team-social a.btn:nth-child(2) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .05s;
}

.team-item:hover .team-social a.btn:nth-child(3) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .1s;
}

.team-item:hover .team-social a.btn:nth-child(4) {
    opacity: 1;
    margin-top: 0;
    transition: .3s .15s;
}

.team-item .team-img img,
.blog-item .blog-img img  {
    transition: .5s;
}

.team-item:hover .team-img img,
.blog-item:hover .blog-img img {
    transform: scale(1.15);
}




/*** Miscellaneous ***/
@media (min-width: 991.98px) {
    .facts {
        position: relative;
        margin-top: -75px;
        z-index: 1;
    }
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

.bg-header {
    background: linear-gradient(rgba(9, 30, 62, .7), rgba(9, 30, 62, .7)), url(../img/carousel-1.jpg) center center no-repeat;
    background-size: cover;
}

.link-animated a {
    transition: .5s;
}

.link-animated a:hover {
    padding-left: 10px;
}

@media (min-width: 767.98px) {
    .footer-about {
        margin-bottom: -75px;
    }
}

/* ---------------- custome styles */
.setmaxWidth600{
    max-width: 600px;
}
.setbclr{
    background: rgba(9, 30, 62, .7);
}
.ChooseDivchild{
    width: 60px;
    height: 60px
}
.setminhight350{
    min-height: 350px;
}
.costSmall{
    font-size: 22px; 
    line-height: 45px;
}
.setMonth{
    font-size: 16px; 
    line-height: 40px;
}
.setZindex{
    z-index: 1;
}
.setHeight55{
    height: 55px;
}
.setHeight150{
    height: 150px;
}
.setMinHeight500{
    min-height: 500px;
}
.maxwidth900{
max-width: 900px;}
}

@keyframes section-title-run {
    0% {left: 0; } 50% { left : 145px; } 100% { left: 0; }
}

@keyframes section-title-run-center {
    0% { left: 50%; margin-left: -75px; } 50% { left : 50%; margin-left: 45px; } 100% { left: 50%; margin-left: -75px; }
}

@keyframes section-title-run-sm {
    0% {left: 0; } 50% { left : 85px; } 100% { left: 0; }
}


/*** Testimonial ***/
.testimonial-carousel{
    visibility: visible !important;
    animation-delay: 0.1s;
    animation-name: fadeInUp;
}

.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: #DDDDDD;
    border-radius: 2px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 30px;
    background: #06A3DA;
}

.testimonial-carousel .owl-item.center {
    position: relative;
    z-index: 1;
}

.testimonial-carousel .owl-item .testimonial-item {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: #FFFFFF !important;
    box-shadow: 0 0 30px #DDDDDD;
}
